<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <b-card>
          <b-row>
            <b-col sm="4" xl="2" md="2">
              <label>*Resource</label>
              <v-select v-model="RESOURCE" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL" :options="RESOURCE_STORE" class="select-size-sm" />
            </b-col>

            <b-col sm="4" xl="2" md="2">
              <label>*Balai</label>
              <v-select v-model="BALAI" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL" :options="BALAI_STORE" class="select-size-sm" />
            </b-col>

            <b-col sm="4" xl="2" md="2">
              <label>Provinsi</label>
              <v-select v-model="PROVINSI" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL" :options="PROVINSI_STORE" class="select-size-sm" />
            </b-col>

            <b-col sm="4" xl="3" md="2">
              <label>Kota/Kabupaten</label>
              <v-select v-model="KOTAKABUPATEN" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="LABEL" :options="KOTAKABUPATEN_STORE" class="select-size-sm" />
            </b-col>

            <b-col sm="4" xl="2" md="2">
              <!-- <label>Bulan</label>
              <a-month-picker placeholder="Pilih Bulan" @change="onChange" :disabledDate="disabledDate" /> -->
              <b-form-group
                label="Bulan"
                label-for="bulan"
              >
              <a-month-picker id="bulan" placeholder="Pilih Bulan" @change="onChange" :disabledDate="disabledDate" />
              </b-form-group>
            </b-col>

            <b-col sm="4 mt-2 pad2" xl="1" md="2">
              <b-form-group>
                <b-form inline id="select-form">
                  <b-button v-on:click="onFetch" variant="primary" class="ml-sm-6" size="">Filter</b-button>
                </b-form>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </div>
    <a-spin :spinning="isLoading">
      <div class="row">
        <div class="col-xl-12">
          <b-card>
            <b-row v-if="showTable">
              <div style="width: 100%; overflow: auto">
                <MeteoTable :RESOURCE="RESOURCE" :BALAI="BALAI" :DATAS="RAW_DATA_TABLE" :PERIODE="DATE_SELECTED" :ExportExcelName="ExportExcelName" @selectionChanged="selectionChanged" @onRowClick="onRowClick" ref="dinamicTableComponent">
                </MeteoTable>
              </div>
            </b-row>
          </b-card>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import vSelect from "vue-select";

import MeteoTable from "../MeteoTable.vue";

import {
  BOverlay,
  BTable,
  BAvatar,
  BBadge,
  BPagination,
  BInputGroupAppend,
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormInput,
  BInputGroup,
  BFormSelect,
  BButton,
  BButtonGroup,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormCheckbox,
  BFormGroup,
  BFormTextarea,
  BPopover,
  BModal,
  VBModal,
  BForm,
  AlertPlugin,
} from "bootstrap-vue";

import metadata from "@/api/metadata";
import monitoring from "@/api/monitoring";
import moment from "moment";

export default {
  components: {
    MeteoTable,
    vSelect,
    BOverlay,
    BTable,
    BAvatar,
    BBadge,
    BPagination,
    BInputGroupAppend,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormInput,
    BInputGroup,
    BFormSelect,
    BButton,
    BButtonGroup,
    BFormDatepicker,
    BFormCheckboxGroup,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BPopover,
    BModal,
    VBModal,
    BForm,
    AlertPlugin,
  },
  data() {
    return {
      ExportExcelName: "BMKGSatu Monitoring",
      isLoading: false,
      showTable: false,
      BALAI: "",
      BALAI_STORE: [],
      PROVINSI: "",
      PROVINSI_STORE: [],
      KOTAKABUPATEN: "",
      KOTAKABUPATEN_STORE: [],
      RESOURCE: "",
      RESOURCE_STORE: [
        { KEY: "22", LABEL: "METAR SPECI", CODE: "METAR_SPECI" },
        { KEY: "21", LABEL: "PIBAL", CODE: "PIBAL" },
        { KEY: "2", LABEL: "SINOPTIK", CODE: "ME_48" },
      ],

      BALAI_SELECTED: "",
      PROVINSI_SELECTED: "",
      KOTAKABUPATEN_SELECTED: "",
      RESOURCE_SELECTED: "",
      DATE_SELECTED: "",
      RAW_DATA_TABLE: [],
      pageOptions: [5, 10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      filter: null,
      filterOn: [],
      fields: [],
      filter_data: "",
      perPage: 10,
      columns: [],
      rows: [],
      DATA_FLAG: [],
      ROW_HIDE: [],
    };
  },
  mounted() {
    this.optionRegion();
  },
  watch: {
    RESOURCE(VALUES) {
      this.RESOURCE_SELECTED = VALUES.KEY;
    },
    BALAI(VALUES) {
      this.BALAI_SELECTED = VALUES.KEY;
      this.listPropinsi(VALUES.KEY);
      this.PROVINSI = [
        {
          KEY: "",
          LABEL: "PILIH PROVINSI",
        },
      ];
      this.PROVINSI_SELECTED = "";
    },
    PROVINSI(VALUES) {
      this.PROVINSI_SELECTED = VALUES.KEY;
      this.listKabupaten(VALUES.KEY);
      this.KOTAKABUPATEN = [
        {
          KEY: "",
          LABEL: "PILIH KOTA/KABUPATEN",
        },
      ];
      this.KOTAKABUPATEN_SELECTED = "";
    },
    KOTAKABUPATEN(VALUES) {
      this.KOTAKABUPATEN_SELECTED = VALUES.KEY;
    },
  },
  methods: {
    moment,
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > moment().endOf("day");
    },
    onChange(date, dateString) {
      this.DATE_SELECTED = dateString;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    onFetch() {
      if (this.RESOURCE_SELECTED == "" || this.BALAI_SELECTED == "" || this.DATE_SELECTED == "") {
        this.$swal({
          title: "ERROR",
          text: "Silahkan Lengkapi Filter",
          icon: "error",
          customClass: {
            confirmButton: "btn btn-danger",
          },
          buttonsStyling: false,
        });
        return;
      }
      if (this.RESOURCE_SELECTED == 21 || this.RESOURCE_SELECTED == "21") {
        this.ROW_HIDE = [];
      } else {
        this.ROW_HIDE = [];
      }
      this.getMonitoring();
    },
    async getMonitoring() {
      this.isLoading = true;
      this.showTable = false;
      this.ExportExcelName = "BMKGSatu Monitoring_" + this.RESOURCE.CODE + "_" + this.DATE_SELECTED;
      try {
        const { data } = await monitoring.getMonitoringMonth(this.RESOURCE_SELECTED, this.BALAI_SELECTED, this.PROVINSI_SELECTED, this.KOTAKABUPATEN_SELECTED, this.DATE_SELECTED);
        if (
          this.DATE_SELECTED.substring(5, 12) == "01" ||
          this.DATE_SELECTED.substring(5, 12) == "03" ||
          this.DATE_SELECTED.substring(5, 12) == "05" ||
          this.DATE_SELECTED.substring(5, 12) == "07" ||
          this.DATE_SELECTED.substring(5, 12) == "08" ||
          this.DATE_SELECTED.substring(5, 12) == "10" ||
          this.DATE_SELECTED.substring(5, 12) == "12"
        ) {
          var cols = [
            { label: "Stasiun ID", field: "station_id" },
            { label: "Stasiun", field: "station_name" },
            { label: "1", field: "tgl1" },
            { label: "2", field: "tgl2" },
            { label: "3", field: "tgl3" },
            { label: "4", field: "tgl4" },
            { label: "5", field: "tgl5" },
            { label: "6", field: "tgl6" },
            { label: "7", field: "tgl7" },
            { label: "8", field: "tgl8" },
            { label: "9", field: "tgl9" },
            { label: "10", field: "tgl10" },

            { label: "11", field: "tgl11" },
            { label: "12", field: "tgl12" },
            { label: "13", field: "tgl13" },
            { label: "14", field: "tgl14" },
            { label: "15", field: "tgl15" },
            { label: "16", field: "tgl16" },
            { label: "17", field: "tgl17" },
            { label: "18", field: "tgl18" },
            { label: "19", field: "tgl19" },
            { label: "20", field: "tgl20" },

            { label: "21", field: "tgl21" },
            { label: "22", field: "tgl22" },
            { label: "23", field: "tgl23" },
            { label: "24", field: "tgl24" },
            { label: "25", field: "tgl25" },
            { label: "26", field: "tgl26" },
            { label: "27", field: "tgl27" },
            { label: "28", field: "tgl28" },
            { label: "29", field: "tgl29" },
            { label: "30", field: "tgl30" },
            { label: "31", field: "tgl31" },
            { label: "Rata-Rata", field: "rata" },
          ];
        } else if (this.DATE_SELECTED.substring(5, 12) == "04" || this.DATE_SELECTED.substring(5, 12) == "06" || this.DATE_SELECTED.substring(5, 12) == "09" || this.DATE_SELECTED.substring(5, 12) == "11") {
          var cols = [
            { label: "Stasiun ID", field: "station_id" },
            { label: "Stasiun", field: "station_name" },
            { label: "1", field: "tgl1" },
            { label: "2", field: "tgl2" },
            { label: "3", field: "tgl3" },
            { label: "4", field: "tgl4" },
            { label: "5", field: "tgl5" },
            { label: "6", field: "tgl6" },
            { label: "7", field: "tgl7" },
            { label: "8", field: "tgl8" },
            { label: "9", field: "tgl9" },
            { label: "10", field: "tgl10" },

            { label: "11", field: "tgl11" },
            { label: "12", field: "tgl12" },
            { label: "13", field: "tgl13" },
            { label: "14", field: "tgl14" },
            { label: "15", field: "tgl15" },
            { label: "16", field: "tgl16" },
            { label: "17", field: "tgl17" },
            { label: "18", field: "tgl18" },
            { label: "19", field: "tgl19" },
            { label: "20", field: "tgl20" },

            { label: "21", field: "tgl21" },
            { label: "22", field: "tgl22" },
            { label: "23", field: "tgl23" },
            { label: "24", field: "tgl24" },
            { label: "25", field: "tgl25" },
            { label: "26", field: "tgl26" },
            { label: "27", field: "tgl27" },
            { label: "28", field: "tgl28" },
            { label: "29", field: "tgl29" },
            { label: "30", field: "tgl30" },
            { label: "Rata-Rata", field: "rata" },
          ];
        } else if ((this.DATE_SELECTED.substring(5, 12) == "02" && 0 == this.DATE_SELECTED.substring(0, 4) % 4 && 0 != this.DATE_SELECTED.substring(0, 4) % 100) || 0 == this.DATE_SELECTED.substring(0, 4) % 400) {
          var cols = [
            { label: "Stasiun ID", field: "station_id" },
            { label: "Stasiun", field: "station_name" },
            { label: "1", field: "tgl1" },
            { label: "2", field: "tgl2" },
            { label: "3", field: "tgl3" },
            { label: "4", field: "tgl4" },
            { label: "5", field: "tgl5" },
            { label: "6", field: "tgl6" },
            { label: "7", field: "tgl7" },
            { label: "8", field: "tgl8" },
            { label: "9", field: "tgl9" },
            { label: "10", field: "tgl10" },

            { label: "11", field: "tgl11" },
            { label: "12", field: "tgl12" },
            { label: "13", field: "tgl13" },
            { label: "14", field: "tgl14" },
            { label: "15", field: "tgl15" },
            { label: "16", field: "tgl16" },
            { label: "17", field: "tgl17" },
            { label: "18", field: "tgl18" },
            { label: "19", field: "tgl19" },
            { label: "20", field: "tgl20" },

            { label: "21", field: "tgl21" },
            { label: "22", field: "tgl22" },
            { label: "23", field: "tgl23" },
            { label: "24", field: "tgl24" },
            { label: "25", field: "tgl25" },
            { label: "26", field: "tgl26" },
            { label: "27", field: "tgl27" },
            { label: "28", field: "tgl28" },
            { label: "29", field: "tgl29" },

            { label: "Rata-Rata", field: "rata" },
          ];
        } else {
          var cols = [
            { label: "Stasiun ID", field: "station_id" },
            { label: "Stasiun", field: "station_name" },
            { label: "1", field: "tgl1" },
            { label: "2", field: "tgl2" },
            { label: "3", field: "tgl3" },
            { label: "4", field: "tgl4" },
            { label: "5", field: "tgl5" },
            { label: "6", field: "tgl6" },
            { label: "7", field: "tgl7" },
            { label: "8", field: "tgl8" },
            { label: "9", field: "tgl9" },
            { label: "10", field: "tgl10" },

            { label: "11", field: "tgl11" },
            { label: "12", field: "tgl12" },
            { label: "13", field: "tgl13" },
            { label: "14", field: "tgl14" },
            { label: "15", field: "tgl15" },
            { label: "16", field: "tgl16" },
            { label: "17", field: "tgl17" },
            { label: "18", field: "tgl18" },
            { label: "19", field: "tgl19" },
            { label: "20", field: "tgl20" },

            { label: "21", field: "tgl21" },
            { label: "22", field: "tgl22" },
            { label: "23", field: "tgl23" },
            { label: "24", field: "tgl24" },
            { label: "25", field: "tgl25" },
            { label: "26", field: "tgl26" },
            { label: "27", field: "tgl27" },
            { label: "28", field: "tgl28" },
            { label: "Rata-Rata", field: "rata" },
          ];
        }

        var raws = [];
        console.log("data : ", data);
        var total_rata = data.total_rata;
        var sort_data = data.RAW;
        sort_data.sort((a, b) => {
          let fa = a.station_name;
          let fb = b.station_name;

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        console.log("sort_data : ", sort_data);
        var datamonitor = sort_data;
        console.log("datamonitor : ", datamonitor);
        var no = 1;
        for (var i in datamonitor) {
          var row = datamonitor[i];
          console.log("Row : ", row);
          var obj = {
            "station_id": row.station_id,
            "station_name": row.station_name,
            "tgl1": row.tgl1,
            "tgl2": row.tgl2,
            "tgl3": row.tgl3,
            "tgl4": row.tgl4,
            "tgl5": row.tgl5,
            "tgl6": row.tgl6,
            "tgl7": row.tgl7,
            "tgl8": row.tgl8,
            "tgl9": row.tgl9,
            "tgl10": row.tgl10,
            "tgl11": row.tgl11,
            "tgl12": row.tgl12,
            "tgl13": row.tgl13,
            "tgl14": row.tgl14,
            "tgl15": row.tgl15,
            "tgl16": row.tgl16,
            "tgl17": row.tgl17,
            "tgl18": row.tgl18,
            "tgl19": row.tgl19,
            "tgl20": row.tgl20,
            "tgl21": row.tgl21,
            "tgl22": row.tgl22,
            "tgl23": row.tgl23,
            "tgl24": row.tgl24,
            "tgl25": row.tgl25,
            "tgl26": row.tgl26,
            "tgl27": row.tgl27,
            "tgl28": row.tgl28,
            "tgl29": row.tgl29 != undefined ? row.tgl29 : "",
            "tgl30": row.tgl30 != undefined ? row.tgl30 : "",
            "tgl31": row.tgl31 != undefined ? row.tgl31 : "",
            "rata": row.rata
          };
          raws.push(obj);
          no++;
        }
        var dataBuild = { DATAS: raws, columns: cols, TOTAL_RATA: total_rata };
        this.RAW_DATA_TABLE = dataBuild;

        this.showTable = true;

        setTimeout(() => {
          this.$refs.dinamicTableComponent.generateTable(this.RAW_DATA_TABLE);
          this.isLoading = false;
        }, 1000);
      } catch (e) {
        this.isLoading = false;
        console.log(e);
      }
    },

    async optionRegion() {
      try {
        const { data } = await metadata.getRegionList();
        // console.log(data);
        for (var i = 0; i < data.length; i++) {
          const dataRegion = {
            KEY: data[i].region_id,
            LABEL: data[i].region_description,
          };
          this.BALAI_STORE.push(dataRegion);
        }
        // this.BALAI = 1
      } catch (e) {
        console.log(e);
      }
    },
    async listPropinsi(region_id) {
      this.PROVINSI_STORE = [];
      try {
        const { data } = await metadata.getPropinsiListByRegion(region_id);
        var _selected = "";
        for (var i = 0; i < data.length; i++) {
          const rowsPropinsi = {
            KEY: data[i].propinsi_id,
            LABEL: data[i].propinsi_name,
          };
          this.PROVINSI_STORE.push(rowsPropinsi);
          _selected = data[i].propinsi_id;
        }
        // this.PROVINSI" = _selected;
      } catch (e) {
        console.log(e);
      }
    },
    async listKabupaten(propinsi_id) {
      this.KOTAKABUPATEN_STORE = [];
      try {
        const { data } = await metadata.getKabupatenListByPropinsi(propinsi_id);
        var _selected = "";
        for (var i = 0; i < data.length; i++) {
          const rowsKabupaten = {
            KEY: data[i].kabupaten_id,
            LABEL: data[i].kabupaten_name,
          };
          this.KOTAKABUPATEN_STORE.push(rowsKabupaten);
          _selected = data[i].kabupaten_id;
        }
        // this.KOTAKABUPATEN = _selected;
      } catch (e) {
        console.log(e);
      }
    },
    onRowClick(params) {
      // console.log(params);
    },
    selectionChanged(params) {
      console.log(params);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
<style>
.header-box-custome {
  background: #3f9cff;
  padding: 10px;
  border-radius: 5px;
  color: #ffffff;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px;
}

.monitor .vgt-table {
  font-size: 12px !important;
}

[dir="ltr"] .monitor .vgt-table th {
  padding: 5px 25px 5px 5px !important;
}

[dir] .monitor table.vgt-table td {
  padding: 5px 25px 5px 5px !important;
}
</style>
