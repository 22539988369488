<template>
  <div>
    <vue-good-table :columns="columns" :rows="rows" @on-row-click="onRowClick"
      @on-selected-rows-change="selectionChanged" :search-options="{
        enabled: true,
      }" :pagination-options="{ enabled: true }" class="monitor">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <!-- <div
          v-if="
            props.column.renderFlag == false && props.column.field != 'progress'
          "
          style="display: block; text-align: center; white-space: nowrap"
        > -->
        <div style="display: block; text-align: center; white-space: nowrap">
          {{ props.row[props.column.field] }}
        </div>
        <div v-if="props.column.field == 'progress'">{{ props.row[props.column.field] }}%</div>
      </template>
    </vue-good-table>
    <div style="padding-top: 10px; padding-bottom: 10px; text-align: right"></div>
    <div>
      <a-button style="padding-top: 10px; padding-bottom: 10px" @click="asexportExcel" class="btn btn-success"
        :sumberdata="sumberdata">Download</a-button>
      <!-- <HelloWorld /> -->
    </div>
    <!-- <a-button @click="cekdata" class="btn-success">dsadas</a-button> -->
  </div>
</template>

<script>
import {
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BListGroup,
  BListGroupItem,
  BCard,
  BCardHeader,
  BCardTitle,
  BTabs,
  BTab,
  BFormDatepicker,
  BAvatar,
  BBadge,
  BPagination,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BTable,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
// import HelloWorld from "./HelloWorld.vue";
import moment from "moment";
moment.locale("id");

export default {
  name: "DinamicTable",
  props: {
    DATAS: Object,
    PERIODE: Object,
    ExportExcelName: String,
    BALAI: Object,
    RESOURCE: Object,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormGroup,
    VueGoodTable,
    saveExcel,
    // HelloWorld,
    ExcelJS,
    saveAs,
  },
  mounted() {
    console.log("DATAS", this.DATAS);
    console.log("route))))", this.$route.path);
  },
  methods: {
    callParent() {
      this.$emit("onAction", { type: "edit" });
    },
    onRowClick(params) {
      this.$emit("onRowClick", params);
    },
    selectionChanged(params) {
      this.$emit("selectionChanged", params);
    },
    refreshDataChild(message) {
      this.columns = message.columns;
      this.rows = message.DATAS;
    },
    generateTable: function generateTable(message) {
      this.columns = message.columns;
      this.rows = message.DATAS;
    },
    onExportExcel() {
      var columnCustome = [];
      for (var i in this.columns) {
        var infoCol = this.columns[i];
        columnCustome.push({
          field: infoCol.field,
          title: infoCol.label,
        });
      }
      saveExcel({
        data: this.rows,
        fileName: this.export_name,
        columns: columnCustome,
      });
    },
    cekdata() {
      console.log("filed : ", this.field_xlsx);
    },
    moment,

    asexportExcel() {
      console.log("this.columns", this.columns);

      const workbook = new ExcelJS.Workbook();
      const filename = "sheet";

      let worksheet = workbook.addWorksheet(filename, {
        // pageSetup: {
        //   horizontalCentered: true,
        //   columns: 8,
        //   verticalCentered: true,
        //   paperSize: 9,
        //   orientation: "portrait",
        //   margins: {
        //     left: 0.3149606,
        //     right: 0.3149606,
        //     top: 0.3543307,
        //     bottom: 0.3543307,
        //     header: 0.3149606,
        //     footer: 0.3149606,
        //   },
        // },
      });
      // workbook.xlsx.writeFile("fileName");

      worksheet.getCell("A7").alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      const cellTanggal1 = worksheet.getCell("C7");
      worksheet.getCell("C7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal2 = worksheet.getCell("D7");
      worksheet.getCell("D7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal3 = worksheet.getCell("E7");
      worksheet.getCell("E7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal4 = worksheet.getCell("F7");
      worksheet.getCell("F7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal5 = worksheet.getCell("G7");
      worksheet.getCell("G7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal6 = worksheet.getCell("H7");
      worksheet.getCell("H7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal7 = worksheet.getCell("I7");
      worksheet.getCell("I7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal8 = worksheet.getCell("J7");
      worksheet.getCell("J7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal9 = worksheet.getCell("K7");
      worksheet.getCell("K7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal10 = worksheet.getCell("L7");
      worksheet.getCell("L7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal11 = worksheet.getCell("M7");
      worksheet.getCell("M7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal12 = worksheet.getCell("N7");
      worksheet.getCell("N7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal13 = worksheet.getCell("O7");
      worksheet.getCell("O7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal14 = worksheet.getCell("P7");
      worksheet.getCell("P7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal15 = worksheet.getCell("Q7");
      worksheet.getCell("Q7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal16 = worksheet.getCell("R7");
      worksheet.getCell("R7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };
      const cellTanggal17 = worksheet.getCell("S7");
      worksheet.getCell("S7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal18 = worksheet.getCell("T7");
      worksheet.getCell("T7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal19 = worksheet.getCell("U7");
      worksheet.getCell("U7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal20 = worksheet.getCell("V7");
      worksheet.getCell("V7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal21 = worksheet.getCell("W7");
      worksheet.getCell("W7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal22 = worksheet.getCell("X7");
      worksheet.getCell("X7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal23 = worksheet.getCell("Y7");
      worksheet.getCell("Y7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal24 = worksheet.getCell("Z7");
      worksheet.getCell("Z7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal25 = worksheet.getCell("AA7");
      worksheet.getCell("AA7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal26 = worksheet.getCell("AB7");
      worksheet.getCell("AB7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal27 = worksheet.getCell("AC7");
      worksheet.getCell("AC7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const cellTanggal28 = worksheet.getCell("AD7");
      worksheet.getCell("AD7").alignment = {
        horizontal: "center",
        vertical: "middle",
      };

      const headerRowTipe = worksheet.getRow(5);
      headerRowTipe.height = 20;
      worksheet.mergeCells("A5:C5");

      headerRowTipe.getCell(1).value = "Tipe FORM: " + this.RESOURCE.LABEL;
      headerRowTipe.getCell(1).font = {
        name: "Arial Black",
        size: 12,
        color: "#000",
        verticalAlignment: "left",
      };
      worksheet.getCell("A5:C5").alignment = {
        horizontal: "left",
        vertical: "middle",
        wrapText: true,
      };

      worksheet.getCell("B7").alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      const cellStationId = worksheet.getCell("A7");
      cellStationId.value = "Stasiun Id";
      worksheet.cell = [{ key: "station_id", width: 50 }];
      const cellStationName = worksheet.getCell("B7");

      cellStationName.value = "Stasiun";
      cellTanggal1.value = "1";
      cellTanggal2.value = "2";
      cellTanggal3.value = "3";
      cellTanggal4.value = "4";
      cellTanggal5.value = "5";
      cellTanggal6.value = "6";
      cellTanggal7.value = "7";
      cellTanggal8.value = "8";
      cellTanggal9.value = "9";
      cellTanggal10.value = "10";
      cellTanggal11.value = "11";
      cellTanggal12.value = "12";
      cellTanggal13.value = "13";
      cellTanggal14.value = "14";
      cellTanggal15.value = "15";
      cellTanggal16.value = "16";
      cellTanggal17.value = "17";
      cellTanggal18.value = "18";
      cellTanggal19.value = "19";
      cellTanggal20.value = "20";
      cellTanggal21.value = "21";
      cellTanggal22.value = "22";
      cellTanggal23.value = "23";
      cellTanggal24.value = "24";
      cellTanggal25.value = "25";
      cellTanggal26.value = "26";
      cellTanggal27.value = "27";
      cellTanggal28.value = "28";

      if (
        this.PERIODE.substring(5, 12) == "01" ||
        this.PERIODE.substring(5, 12) == "03" ||
        this.PERIODE.substring(5, 12) == "05" ||
        this.PERIODE.substring(5, 12) == "07" ||
        this.PERIODE.substring(5, 12) == "08" ||
        this.PERIODE.substring(5, 12) == "10" ||
        this.PERIODE.substring(5, 12) == "12"
      ) {
        const headerRowHasil = worksheet.getRow(1);
        headerRowHasil.height = 30;
        worksheet.mergeCells("A1:AH1");

        headerRowHasil.getCell(1).value = "Hasil Monitoring Data Bulan" + moment(this.PERIODE).format(" MMMM  YYYY");
        headerRowHasil.getCell(1).font = {
          name: "Arial Black",
          size: 16,
          bold: true,
          color: "#000",
        };
        headerRowHasil.getCell(1).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        const headerRowBalai = worksheet.getRow(3);
        headerRowBalai.height = 30;
        worksheet.mergeCells("A3:AH3");

        headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

        headerRowBalai.getCell(1).font = {
          name: "Arial Black",
          size: 16,
          verticalAlignment: "center ",
          bold: true,
          color: "#000",
        };
        headerRowBalai.getCell(1).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        worksheet.getCell("AH7").value = "Rata - rata";
        worksheet.getCell("AH7").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getColumn(29).numFmt = '#,#0.00"%"';

        worksheet.columns = [
          { key: "station_id", width: 10 },
          { key: "station_name", width: 30 },
          { key: "tgl1", width: 7 },
          { key: "tgl2", width: 7 },
          { key: "tgl3", width: 7 },
          { key: "tgl4", width: 7 },
          { key: "tgl5", width: 7 },
          { key: "tgl6", width: 7 },
          { key: "tgl7", width: 7 },
          { key: "tgl8", width: 7 },
          { key: "tgl9", width: 7 },
          { key: "tgl10", width: 7 },
          { key: "tgl11", width: 7 },
          { key: "tgl12", width: 7 },
          { key: "tgl13", width: 7 },
          { key: "tgl14", width: 7 },
          { key: "tgl15", width: 7 },
          { key: "tgl16", width: 7 },
          { key: "tgl17", width: 7 },
          { key: "tgl18", width: 7 },
          { key: "tgl19", width: 7 },
          { key: "tgl20", width: 7 },
          { key: "tgl21", width: 7 },
          { key: "tgl22", width: 7 },
          { key: "tgl23", width: 7 },
          { key: "tgl24", width: 7 },
          { key: "tgl25", width: 7 },
          { key: "tgl26", width: 7 },
          { key: "tgl27", width: 7 },
          { key: "tgl28", width: 7 },
          { key: "tgl29", width: 7 },
          { key: "tgl30", width: 7 },
          { key: "tgl31", width: 7 },
          { key: "rata", width: 10, style: { numFmt: '#,#0.00"%"' } },
        ];
        worksheet.addRows(this.rows);
        worksheet.getRow(7).eachCell((cell) =>
          Object.assign(cell, {
            border: {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            },
          })
        );

        const rowCount = worksheet.rowCount;
        for (let i = 6; i <= rowCount; i++) {
          const row = worksheet.getRow(7 + i);
          const data = worksheet.getRow(1 + i);

          for (let index = 1; index <= 34; index++) {
            data.getCell(index).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        }
        worksheet.mergeCells(`A${rowCount + 1}:AG${rowCount + 1}`);
        worksheet.getCell(`A${rowCount + 1}:AG${rowCount + 1}`).value = "TOTAL";
        worksheet.getCell(`AH${rowCount + 1}`).value = this.DATAS.TOTAL_RATA;
        worksheet.getCell(`A${rowCount}:AG${rowCount}`).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        const cellTanggal29 = worksheet.getCell("AE7");
        worksheet.getCell("AE7").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("AE7").border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };

        const cellTanggal30 = worksheet.getCell("AF7");
        worksheet.getCell("AF7").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("AF7").border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        const cellTanggal31 = worksheet.getCell("AG7");
        worksheet.getCell("AG7").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("AG7").border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cellTanggal29.value = "29";
        cellTanggal30.value = "30";
        cellTanggal31.value = "31";
        worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.getCell(`A${rowCount + 1}`).font = { bold: true };
        worksheet.getCell(`A${rowCount}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
        worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
        worksheet.getCell(`A${rowCount + 4}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
        worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
        worksheet.getCell(`A${rowCount + 5}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
        worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
        worksheet.getCell(`A${rowCount + 6}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`AD${rowCount + 5}:AH${rowCount + 5}`);
        worksheet.getCell(`AD${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
        worksheet.getCell(`AD${rowCount + 5}`).alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`AD${rowCount + 6}:AH${rowCount + 6}`);
        worksheet.getCell(`AD${rowCount + 6}`).value = "Pembuat Laporan, ";
        worksheet.getCell(`AD${rowCount + 6}`).alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true,
        };
      } else if (this.PERIODE.substring(5, 12) == "04" || this.PERIODE.substring(5, 12) == "06" || this.PERIODE.substring(5, 12) == "09" || this.PERIODE.substring(5, 12) == "11") {
        const headerRowHasil = worksheet.getRow(1);
        headerRowHasil.height = 30;
        worksheet.mergeCells("A1:AG1");

        headerRowHasil.getCell(1).value = "Hasil Monitoring Data Bulan" + moment(this.PERIODE).format(" MMMM  YYYY");
        headerRowHasil.getCell(1).font = {
          name: "Arial Black",
          size: 16,
          bold: true,
          color: "#000",
        };
        headerRowHasil.getCell(1).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        const headerRowBalai = worksheet.getRow(3);
        headerRowBalai.height = 30;
        worksheet.mergeCells("A3:AG3");

        headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

        headerRowBalai.getCell(1).font = {
          name: "Arial Black",
          size: 16,
          verticalAlignment: "center ",
          bold: true,
          color: "#000",
        };
        headerRowBalai.getCell(1).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        worksheet.getCell("AG7").value = "Rata - rata";
        worksheet.getCell("AG7").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getColumn(29).numFmt = '#,#0.00"%"';

        worksheet.columns = [
          { key: "station_id", width: 10 },
          { key: "station_name", width: 30 },
          { key: "tgl1", width: 7 },
          { key: "tgl2", width: 7 },
          { key: "tgl3", width: 7 },
          { key: "tgl4", width: 7 },
          { key: "tgl5", width: 7 },
          { key: "tgl6", width: 7 },
          { key: "tgl7", width: 7 },
          { key: "tgl8", width: 7 },
          { key: "tgl9", width: 7 },
          { key: "tgl10", width: 7 },
          { key: "tgl11", width: 7 },
          { key: "tgl12", width: 7 },
          { key: "tgl13", width: 7 },
          { key: "tgl14", width: 7 },
          { key: "tgl15", width: 7 },
          { key: "tgl16", width: 7 },
          { key: "tgl17", width: 7 },
          { key: "tgl18", width: 7 },
          { key: "tgl19", width: 7 },
          { key: "tgl20", width: 7 },
          { key: "tgl21", width: 7 },
          { key: "tgl22", width: 7 },
          { key: "tgl23", width: 7 },
          { key: "tgl24", width: 7 },
          { key: "tgl25", width: 7 },
          { key: "tgl26", width: 7 },
          { key: "tgl27", width: 7 },
          { key: "tgl28", width: 7 },
          { key: "tgl29", width: 7 },
          { key: "tgl30", width: 7 },
          { key: "rata", width: 10, style: { numFmt: '#,#0.00"%"' } },
        ];
        worksheet.addRows(this.rows);

        const rowCount = worksheet.rowCount;
        for (let i = 6; i <= rowCount; i++) {
          const row = worksheet.getRow(7 + i);
          const data = worksheet.getRow(1 + i);

          for (let index = 1; index <= 33; index++) {
            data.getCell(index).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        }

        for (let i = 6; i <= rowCount; i++) {
          const row = worksheet.getRow(7 + i);
          const data = worksheet.getRow(1 + i);

          for (let index = 3; index <= 33; index++) {
            data.getCell(index).alignment = {
          horizontal: "center",
          vertical: "middle",
         
        };
          }
        }

        worksheet.mergeCells(`A${rowCount + 1}:AF${rowCount + 1}`);
        worksheet.getCell(`A${rowCount + 1}:AF${rowCount + 1}`).value = "TOTAL";
        worksheet.getCell(`AG${rowCount + 1}`).value = this.DATAS.TOTAL_RATA;
        worksheet.getCell(`A${rowCount}:AF${rowCount}`).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        const cellTanggal29 = worksheet.getCell("AE7");
        worksheet.getCell("AE7").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("AE7").border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        const cellTanggal30 = worksheet.getCell("AF7");
        worksheet.getCell("AF7").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("AF7").border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cellTanggal29.value = "29";
        cellTanggal30.value = "30";
        worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.getCell(`A${rowCount + 1}`).font = { bold: true };
        worksheet.getCell(`A${rowCount}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
        worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
        worksheet.getCell(`A${rowCount + 4}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
        worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
        worksheet.getCell(`A${rowCount + 5}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
        worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
        worksheet.getCell(`A${rowCount + 6}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`AC${rowCount + 5}:AG${rowCount + 5}`);
        worksheet.getCell(`AC${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
        worksheet.getCell(`AC${rowCount + 5}`).alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`AC${rowCount + 6}:AG${rowCount + 6}`);
        worksheet.getCell(`AC${rowCount + 6}`).value = "Pembuat Laporan, ";
        worksheet.getCell(`AC${rowCount + 6}`).alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.getRow(7).eachCell((cell) =>
          Object.assign(cell, {
            border: {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            },
          })
        );
      } else if ((this.PERIODE.substring(5, 12) == "02" && 0 == this.PERIODE.substring(0, 4) % 4 && 0 != this.PERIODE.substring(0, 4) % 100) || 0 == this.PERIODE.substring(0, 4) % 400) {
        const headerRowHasil = worksheet.getRow(1);
        headerRowHasil.height = 30;
        worksheet.mergeCells("A1:AF1");

        headerRowHasil.getCell(1).value = "Hasil Monitoring Data Bulan" + moment(this.PERIODE).format(" MMMM  YYYY");
        headerRowHasil.getCell(1).font = {
          name: "Arial Black",
          size: 16,
          bold: true,
          color: "#000",
        };
        headerRowHasil.getCell(1).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        const headerRowBalai = worksheet.getRow(3);
        headerRowBalai.height = 30;
        worksheet.mergeCells("A3:AF3");

        headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

        headerRowBalai.getCell(1).font = {
          name: "Arial Black",
          size: 16,
          verticalAlignment: "center ",
          bold: true,
          color: "#000",
        };
        headerRowBalai.getCell(1).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        worksheet.getCell("AF7").value = "Rata - rata";
        worksheet.getCell("AF7").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getColumn(29).numFmt = '#,#0.00"%"';

        worksheet.columns = [
          { key: "station_id", width: 10 },
          { key: "station_name", width: 30 },
          { key: "tgl1", width: 7 },
          { key: "tgl2", width: 7 },
          { key: "tgl3", width: 7 },
          { key: "tgl4", width: 7 },
          { key: "tgl5", width: 7 },
          { key: "tgl6", width: 7 },
          { key: "tgl7", width: 7 },
          { key: "tgl8", width: 7 },
          { key: "tgl9", width: 7 },
          { key: "tgl10", width: 7 },
          { key: "tgl11", width: 7 },
          { key: "tgl12", width: 7 },
          { key: "tgl13", width: 7 },
          { key: "tgl14", width: 7 },
          { key: "tgl15", width: 7 },
          { key: "tgl16", width: 7 },
          { key: "tgl17", width: 7 },
          { key: "tgl18", width: 7 },
          { key: "tgl19", width: 7 },
          { key: "tgl20", width: 7 },
          { key: "tgl21", width: 7 },
          { key: "tgl22", width: 7 },
          { key: "tgl23", width: 7 },
          { key: "tgl24", width: 7 },
          { key: "tgl25", width: 7 },
          { key: "tgl26", width: 7 },
          { key: "tgl27", width: 7 },
          { key: "tgl28", width: 7 },
          { key: "tgl29", width: 7 },
          { key: "rata", width: 10, style: { numFmt: '#,#0.00"%"' } },
        ];
        worksheet.addRows(this.rows);

        const rowCount = worksheet.rowCount;
        for (let i = 6; i <= rowCount; i++) {
          const row = worksheet.getRow(7 + i);
          const data = worksheet.getRow(1 + i);

          for (let index = 1; index <= 32; index++) {
            data.getCell(index).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        }
        worksheet.mergeCells(`A${rowCount + 1}:AE${rowCount + 1}`);
        worksheet.getCell(`A${rowCount + 1}:AE${rowCount + 1}`).value = "TOTAL";
        worksheet.getCell(`AF${rowCount + 1}`).value = this.DATAS.TOTAL_RATA;
        worksheet.getCell(`A${rowCount}:AE${rowCount}`).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        const cellTanggal29 = worksheet.getCell("AE7");
        worksheet.getCell("AE7").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getCell("AE7").border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cellTanggal29.value = "29";
        worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.getCell(`A${rowCount + 1}`).font = { bold: true };
        worksheet.getCell(`A${rowCount}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
        worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
        worksheet.getCell(`A${rowCount + 4}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
        worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
        worksheet.getCell(`A${rowCount + 5}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
        worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
        worksheet.getCell(`A${rowCount + 6}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`AC${rowCount + 5}:AF${rowCount + 5}`);
        worksheet.getCell(`AC${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
        worksheet.getCell(`AC${rowCount + 5}`).alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`AC${rowCount + 6}:AF${rowCount + 6}`);
        worksheet.getCell(`AC${rowCount + 6}`).value = "Pembuat Laporan, ";
        worksheet.getCell(`AC${rowCount + 6}`).alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.getRow(7).eachCell((cell) =>
          Object.assign(cell, {
            border: {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            },
          })
        );
      } else {
        const headerRowHasil = worksheet.getRow(1);
        headerRowHasil.height = 30;
        worksheet.mergeCells("A1:AE1");

        headerRowHasil.getCell(1).value = "Hasil Monitoring Data Bulan" + moment(this.PERIODE).format(" MMMM  YYYY");
        headerRowHasil.getCell(1).font = {
          name: "Arial Black",
          size: 16,
          bold: true,
          color: "#000",
        };
        headerRowHasil.getCell(1).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        const headerRowBalai = worksheet.getRow(3);
        headerRowBalai.height = 30;
        worksheet.mergeCells("A3:AE3");

        headerRowBalai.getCell(1).value = "Balai Besar MKG " + this.BALAI.LABEL;

        headerRowBalai.getCell(1).font = {
          name: "Arial Black",
          size: 16,
          verticalAlignment: "center ",
          bold: true,
          color: "#000",
        };
        headerRowBalai.getCell(1).alignment = {
          horizontal: "center",
          vertical: "middle",
        };

        worksheet.getCell("AE7").value = "Rata - rata";
        worksheet.getCell("AE7").alignment = {
          horizontal: "center",
          vertical: "middle",
        };
        worksheet.getColumn(29).numFmt = '#,#0.00"%"';

        worksheet.columns = [
          { key: "station_id", width: 10 },
          { key: "station_name", width: 30 },
          { key: "tgl1", width: 7 },
          { key: "tgl2", width: 7 },
          { key: "tgl3", width: 7 },
          { key: "tgl4", width: 7 },
          { key: "tgl5", width: 7 },
          { key: "tgl6", width: 7 },
          { key: "tgl7", width: 7 },
          { key: "tgl8", width: 7 },
          { key: "tgl9", width: 7 },
          { key: "tgl10", width: 7 },
          { key: "tgl11", width: 7 },
          { key: "tgl12", width: 7 },
          { key: "tgl13", width: 7 },
          { key: "tgl14", width: 7 },
          { key: "tgl15", width: 7 },
          { key: "tgl16", width: 7 },
          { key: "tgl17", width: 7 },
          { key: "tgl18", width: 7 },
          { key: "tgl19", width: 7 },
          { key: "tgl20", width: 7 },
          { key: "tgl21", width: 7 },
          { key: "tgl22", width: 7 },
          { key: "tgl23", width: 7 },
          { key: "tgl24", width: 7 },
          { key: "tgl25", width: 7 },
          { key: "tgl26", width: 7 },
          { key: "tgl27", width: 7 },
          { key: "tgl28", width: 7 },
          { key: "rata", width: 10, style: { numFmt: '#,#0.00"%"' } },
        ];
        worksheet.addRows(this.rows);

        const rowCount = worksheet.rowCount;
        for (let i = 6; i <= rowCount; i++) {
          const row = worksheet.getRow(31 + i);
          const data = worksheet.getRow(1 + i);

          for (let index = 1; index <= 31; index++) {
            data.getCell(index).border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          }
        }
        worksheet.getCell(`A${rowCount + 1}:H${rowCount + 1}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.getCell(`A${rowCount + 1}`).font = { bold: true };
        worksheet.getCell(`A${rowCount}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 4}:B${rowCount + 4}`);
        worksheet.getCell(`A${rowCount + 4}`).value = "Mengetahui.";
        worksheet.getCell(`A${rowCount + 4}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 5}:B${rowCount + 5}`);
        worksheet.getCell(`A${rowCount + 5}`).value = "Sub Koordinator";
        worksheet.getCell(`A${rowCount + 5}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`A${rowCount + 6}:B${rowCount + 6}`);
        worksheet.getCell(`A${rowCount + 6}`).value = "Bidang Manajemen Database MKG,";
        worksheet.getCell(`A${rowCount + 6}`).alignment = {
          horizontal: "center",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`AB${rowCount + 5}:AE${rowCount + 5}`);
        worksheet.getCell(`AB${rowCount + 5}`).value = "Jakarta, " + moment().format("DD MMMM  YYYY");
        worksheet.getCell(`AB${rowCount + 5}`).alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.mergeCells(`AB${rowCount + 6}:AE${rowCount + 6}`);
        worksheet.getCell(`AB${rowCount + 6}`).value = "Pembuat Laporan, ";
        worksheet.getCell(`AB${rowCount + 6}`).alignment = {
          horizontal: "left",
          vertical: "middle",
          wrapText: true,
        };
        worksheet.getRow(7).eachCell((cell) =>
          Object.assign(cell, {
            border: {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            },
          })
        );
      }

      // FOOTER
      worksheet.getColumn(1).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.getColumn(3).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };
      worksheet.getColumn(4).alignment = {
        horizontal: "center",
        vertical: "middle",
        wrapText: true,
      };

      workbook.xlsx
        .writeBuffer()
        .then((buffer) => saveAs(new Blob([buffer]), `${this.export_name}.xlsx`))
        .catch((err) => console.log("Error writing excel export", err));
    },
  },
  data: function () {
    return {
      columns: [],
      rows: [],
      searchTerm: "",
      export_name: this.ExportExcelName,
      export_xlsx: this.ExportExcelName + ".xlsx",
      field_xlsx: {},
      balaidata: [],
      sumberdata: [],
    };
  },
  watch: {
    columns(val) {
      var columnCustome = {};
      for (var i in val) {
        var infoCol = val[i];
        var label = String(infoCol.label);
        if (infoCol.field == "total") {
          label = "Total";
        }
        if (infoCol.field != "station_id" && infoCol.field != "station_name" && infoCol.field != "periode" && infoCol.field != "total") {
          columnCustome["'" + label + "'"] = infoCol.field;
        } else {
          columnCustome[label] = infoCol.field;
        }
      }
      this.field_xlsx = columnCustome;
    },
  },
};
</script>

<style>
.icons-visual {
  font-size: 15px !important;
  display: block;
  text-align: center;
  font-weight: bold;
  margin: 5px -15px 8px 0px;
}

.monitor table.vgt-table td {
  padding: 10px !important;
  vertical-align: inherit !important;
}

.monitor .vgt-left-align {
  padding: 10px !important;
  vertical-align: inherit !important;
}

.monitor table.vgt-table {
  font-size: 12px !important;
}
</style>
