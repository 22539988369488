<template>
  <div>
    <b-tabs>
    <b-tab title="HARIAN">
      <MDays />
    </b-tab>
    <b-tab title="BULANAN" lazy>
      <MMonth/>
    </b-tab>
  </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import MDays from "./MDays.vue";
import MMonth from "./MMonth.vue";
import helper from "@/api/helper";


export default {
  components: {
    BTabs,
    BTab,
    BCard,
    MDays,
    MMonth,
  },
  data() {
    return {
      text : ""
    };
  },
  mounted() {
    
  },
  watch: {
   
  },
  methods: {
    
  },
};
</script>
